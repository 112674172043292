<template>
  <div class="navbar-container d-flex content align-items-center">

    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
        >
          <feather-icon
              icon="MenuIcon"
              size="21"
          />
        </b-link>
      </li>
    </ul>

    <div :class="isDemo ? 'bookmark-wrapper align-items-center d-none d-lg-flex' : 'bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex'">
      <bookmarks/>
    </div>

    <div class="align-items-center justify-content-center ml-auto mr-auto" v-if="isDemo">
      <b-badge pill :variant="`warning`">
        <h5 class="text-white">Demo kullanım içindir. Gerçek işlemleriniz için kullanmayınız...</h5>
      </b-badge>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block"/>
      <search-bar/>
      <notification-dropdown/>
      <user-dropdown/>
    </b-navbar-nav>

  </div>
</template>

<script>
import { BLink, BNavbarNav, BBadge } from 'bootstrap-vue'
import Bookmarks from '@core/layouts/components/app-navbar/components/Bookmarks.vue'
import SearchBar from '@core/layouts/components/app-navbar/components/SearchBar.vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
import UserDropdown from '@core/layouts/components/app-navbar/components/UserDropdown.vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BBadge,
    BLink,
    BNavbarNav,

    Bookmarks,
    SearchBar,
    DarkToggler,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      isDemo: $themeConfig.app.appIsDemo,
    }
  },
}
</script>
