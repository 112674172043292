export default [
    {
        header: 'GENEL',
    },
    {
        title: 'Dashboard',
        route: 'home',
        icon: 'HomeIcon',
    },
    {
        header: 'ORGANİZASYON',
    },
    {
        title: 'Şirket Listesi',
        route: 'activity-list',
        icon: 'GridIcon',
    },
    {
        title: 'İşyeri Listesi',
        route: 'activity-list',
        icon: 'MapPinIcon',
    },
    {
        title: 'Departman Listesi',
        route: 'activity-list',
        icon: 'LayersIcon',
    },
    {
        title: 'Birim Listesi',
        route: 'activity-list',
        icon: 'CpuIcon',
    },
    {
        title: 'Pozisyon Listesi',
        route: 'activity-list',
        icon: 'PocketIcon',
    },
    {
        title: 'Çalışan Listesi',
        route: 'definition-salesman-list',
        icon: 'UsersIcon',
    },
    {
        title: 'Organizasyon Şeması',
        route: 'activity-list',
        icon: 'FigmaIcon',
    },
    {
        header: 'FORMLAR',
    },
    {
        title: 'İzin Formları',
        route: 'definition-salesman-list',
        icon: 'SunsetIcon',
    },
    {
        title: 'Masraf Formları',
        route: 'definition-region-list',
        icon: 'BookIcon',
    },
    {
        title: 'Seyahat Formları',
        route: 'definition-region-list',
        icon: 'BriefcaseIcon',
    },
    {
        title: 'Fazla Mesai Formları',
        route: 'definition-region-list',
        icon: 'WatchIcon',
    },
    {
        title: 'Talep Formları',
        route: 'definition-region-list',
        icon: 'InboxIcon',
    },
    {
        header: 'TANIMLAR',
    },
    {
        title: 'İş Akışı Tanımları',
        route: 'definition-region-list',
        icon: 'SlidersIcon',
    },
    {
        title: 'Erişim Tanımları',
        route: 'definition-access-list',
        icon: 'PackageIcon',
    },
    {
        header: 'SİSTEM',
    },
    {
        route: 'system-settings',
        title: 'Ayarlar',
        icon: 'SettingsIcon',
    },
    {
        route: 'system-role-list',
        title: 'Rol Yönetimi',
        icon: 'LockIcon',
    },
    {
        route: 'system-notification-list',
        title: 'Bildirim Yönetimi',
        icon: 'BellIcon',
    },
    {
        route: 'system-page-list',
        title: 'Sayfa Yönetimi',
        icon: 'LayoutIcon',
    },
    {
        route: 'system-definition-list',
        title: 'Tanım Yönetimi',
        icon: 'TrelloIcon',
    },
]
